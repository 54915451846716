<template>
  <div class="navPage">
    <a-menu
      :open-keys="openKeys"
      :selected-keys="selectedKeys"
      mode="inline"
      @click="handleClick"
    >
    <block v-for="(item,index) in route" :key="index">
        <a-menu-item :link="item.link" :key="item.name" v-if="!item['children'] || item['children'].length == 0">
          <span>{{item.title}}</span>
        </a-menu-item>
        <a-sub-menu :link="item.link" :key="item.name" v-else>
          <template #title>{{item.title}}</template>
          <a-menu-item v-for="(itm) in item.children"  :key="itm.name"><i class="bg select"></i>{{itm.title}}</a-menu-item>
        </a-sub-menu>
    </block>
      
    </a-menu>
  </div>
</template>
<script>
export default {
  props: {
    route: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedKeys: [],
      openKeys: [],
    };
  },
  created() {
    let _name = this.$route.name;
    this.route.forEach((item=>{
      if(item.name == _name){
        this.selectedKeys = [item.name];
      }
      if(item.children && item.children.length != 0){
        item.children.forEach((itm)=>{
          if(itm.name == _name){
            this.openKeys = [item.name];
            this.selectedKeys = [itm.name];
          }
        })
      }
    }));
  },
  methods: {
    handleClick({ item, key, keyPath }){
      if(!key){
        return;
      }
      this.$router.push({name:`${key}`})
      
    }
  },
  watch:{
    $route(to, from) {
     let _name = to.name;
    this.route.forEach((item=>{
      if(item.name == _name){
        this.selectedKeys = [item.name];
      }
      if(item.children && item.children.length != 0){
        item.children.forEach((itm)=>{
          if(itm.name == _name){
            this.openKeys = [item.name];
            this.selectedKeys = [itm.name];
          }
        })
      }
    }));
    }
  },
  computed: {},
};
</script>
<style lang="scss">
@import "index.scss";
</style>