<template>
  <div class="headerPage">
    <div class="w float">
      <div class="logo left">诺为欣麻醉软件</div>
      <ul class="tabs left">
        <li class="item" :class="item.name == $route.meta.type?'active':''" v-for="(item,index) in routerList" :key="index" v-html="item.title" @click="toPage(item.path)"></li>
      </ul>
      <div class="dropdown right">
        <h4>{{hostipalName || '暂无'}}</h4>
        <a-dropdown>
          <a class="ant-dropdown-link right" @click.prevent>
            切换课程
            <i class="bg"></i>
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item v-for="(item,index) in classInfo" :key="index">
                <div @click="changeClassIndex(index)">{{item.classFullName || '暂无'}}</div>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import setting from '@/config/setting';
import { mapGetters, mapActions } from "vuex";
import { defineAsyncComponent } from "vue";
export default {
  
  data() {
    return {
      hostipalName:'',
      classInfo:[],
      routerList:[{
        title:'首&nbsp;&nbsp;页',
        name:"index",
        path:''
      },{
        title:'学习记录与统计',
        name:"study",
        path:'study'
      },{
        title:'个人中心',
        name:"userinfo",
        path:'userinfo'
      },{
        title:'使用帮助',
        name:"about",
        path:'about'
      }]
    };
  },
  created(){
    let info = this.userInfo || {};
    this.hostipalName = info.hostipalName;
    this.classInfo = info.classInfo;
    console.log(this.$route)
  },
  methods:{
    ...mapActions({
      submitStep:"exma/submitStep",
      setclassIndex: "user/setclassIndex",
    }),
    toPage(page){
      console.log('page',page)
      this.$router.push(`/${page}`)
    },
    changeClassIndex(index){
      this.setclassIndex(index || 0);
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
};
</script>
<style lang="scss">
@import "index.scss";
</style>