<template>
  <div class="aboutPage">
    <Header />
    <Nav :route="navList" />
    <div class="content">
        <router-view></router-view>
    </div>
    
  </div>
</template>
<script>
import Header from "@/components/header";
import Nav from "@/components/nav";
export default {
  name: "about",
  components: {
    Header,
    Nav
  },
  data() {
    return {
        navList:[{
            title:'使用帮助',
            name:'AboutHelp',
        },],
    };
  },
};
</script>
<style lang="scss">
@import "index.scss";
</style>